<template>
    <section>
        <div class="card-carrito d-flex mb-3 mr-2 p-2 bg-white">
            <img :src="producto.imagen" width="80" height="80" class="obj-cover" />
            <div class="col px-2 text-general pt-2">
                <div class="row mx-0 align-items-center">
                    <div class="col px-0">
                        <p class="f-12 f-400 nombre-producto"> {{ producto.nombre }} </p>
                    </div>
                    <div class="bg-white circled-heart d-flex ml-auto rounded-circle a-center favorite-button j-center">
                        <i :class="`${producto.favorito ? 'icon-heart text-red' : 'icon-heart-outline text-gris2'} cr-pointer`" @click="updateFavoritos" />
                    </div>
                </div>
                <p class="text-general2 f-12">{{ producto.presentacion }}</p>
                <div v-if="producto.promocion" class="row mx-0 f-14">
                    <p class="col-auto pl-0 pr-1 text-general f-600"> {{ convertMoney(funValor(producto.total_carrito)) }} </p>
                    <p class="col-auto px-2 text-gris f-600 descuento">
                        {{ convertMoney(funValor(producto.total_teorico_carrito)) }}
                    </p>
                </div>
                <div v-else class="row mx-0 f-14">
                    <p class="col-auto pl-0 pr-1 text-general f-600"> {{ convertMoney(funValor(producto.total_carrito)) }} </p>
                </div>
                <div class="row mx-0 mt-2">
                    <div class="d-middle button-cant">
                        <el-input-number
                        v-show="!sinStock || producto.stock_disponible > 0"
                        v-model="producto.cant_carrito"
                        :min="parseFloat(producto.compra_minima)"
                        :max="maximaCompra"
                        :step="parseFloat(producto.cantidad_minima)"
                        :step-strictly="entero"
                        :precision="precision"
                        size="small" class="ml-auto"
                        style="width:150px;"
                        @change="cambiarCantidad($event)"
                        />
                        <div v-show="sinStock && producto.stock_disponible == 0" class="border br-10 text-white px-4 f-14 bg-black">
                            Agotado
                        </div>
                    </div>
                    <div v-show="sinStock && producto.stock_disponible > 0" class="f-12 px-2">
                        Max. {{ formatNumero(producto.stock_disponible) }} unidades
                    </div>
                    <div class="delete ml-auto" @click="eliminarProducto">
                        <i class="icon-trash-outline f-12" />
                    </div>
                </div>
                <div class="row mx-0 mt-2">
                    <div v-if="producto.promocion" class="pill-descuento f-12 px-2">
                        {{ promocion.texto }}
                    </div>
                    <div v-show="$usuario.tipo_impuesto === 1 && producto.porcentaje_impuesto > 0" class="pill f-12 px-2 ml-1">
                        + IVA
                    </div>
                    <div v-show="$usuario.tipo_impuesto === 2 && producto.porcentaje_impuesto > 0" class="pill f-12 px-2 ml-1">
                        IVA incluido
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Carrito from '~/services/carrito/carrito'
import Favoritos from '~/services/favoritos/favoritos'
export default {
    props: {
        producto: {
            type: Object,
            default(){return {
                id_producto:1,
                cant_carrito:20,
                nombre:"producto_nuevo",
                presentacion:'600 ml',
                favorito:1,
                imagen:'https://www.las2orillas.co/wp-content/uploads/2021/04/biscolata-600x600.jpeg',
                cantidad_tipo:1,
                cantidad_minima: 1,
                compra_maxima:20,
                compra_minima:1,
                porcentaje_impuesto: null,
                promocion:0,
                Descripcion:'Descripcionnnnnn....',
                Imagenes:[]
            }}
            
            
        },
        sinStock: {
            type: Boolean,
            default: false
        },
        promocion:{
            type:Object,
            default(){return {}}
        }
    },
    data(){
        return {
            favorito: false,
            cant_producto: 0,
            cantidadBase: null
        }
    },
    computed: {
        entero(){
            if(this.producto.cantidad_tipo === 1){
                return true
            }else{
                return false
            }
        },
        precision(){
            if(this.producto.cantidad_tipo === 1){
                return 0
            }else{
                return 2
            }
        },
        impuesto(){
            const i = this.producto.porcentaje_impuesto
            return i ? i : 0
        },
        maximaCompra(){
            if(this.producto.compra_maxima === 0){
                return 999999
            }
            if(this.producto.cant_carrito > this.producto.compra_maxima){
                return parseFloat(this.producto.cant_carrito)
            }
            return parseFloat(this.producto.compra_maxima)
        }
    },
    methods: {
        agregarFavorito(){
            this.favorito = !this.favorito;
        },
        accionCard(){
            if(this.infoProduct){
                this.$refs.modalProducto.toggle();
            }
        },
        async eliminarProducto(){
            try {
                const { data } = await Carrito.eliminarProductoCarrito(this.producto.id_producto)
                this.notificacion('Éxito', data.mensaje, 'success')
                this.$usuario.cant_productos_carrito = data.productos_en_carrito;
                this.$emit("eliminarPedido")
                this.$emit("carritoActualizado")
            } catch (error){
                return this.errorCatch(error)
            }
        },
        funValor(value){
            if(this.$usuario.tipo_impuesto === 0){
                return value
            }
            if(this.$usuario.tipo_impuesto === 1){
                return value / (1 + this.impuesto / 100)
            }
            if(this.$usuario.tipo_impuesto === 2 || this.$usuario.tipo_impuesto === 3){
                return value
            }
            return 0
        },
        cambiarCantidad(cantidad){
            if(this.producto.compra_maxima != 0){
                if(cantidad > this.producto.compra_maxima){
                    return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto. Permitido '+this.producto.compra_maxima+' Unidades','warning');
                }
            } else {
                if(cantidad > 99999){
                    return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto.','warning');
                }
            }
            const change = () => {
                this.updateCantidad(cantidad)
            }
            this.delay(change)
        },
        async updateCantidad(cantidad){
            try {
                const form = {
                    idProducto:this.producto.id_producto,
                    cantidad:cantidad
                }
                const {data} = await Carrito.updateCantidad(form)
                this.producto.total_carrito = data.producto.total_final
                this.producto.total_teorico_carrito = data.producto.total_teorico
                this.$emit("carritoActualizado")
               
            } catch (error){
                this.errorCatch(error)
            }
        },
        async updateFavoritos(){
            try {

                if(this.producto.favorito){
                    const {data} = await Favoritos.deleteFavorito(this.producto.id_producto)
                    this.producto.favorito = 0
                    this.notificacion('Eliminado','Producto eliminado de los favoritos','success')
                }else{
                    const {data} = await Favoritos.addFavorito(this.producto.id_producto)
                    this.producto.favorito = 1
                    this.notificacion('','Producto agregado a favoritos','success')
                }
               
            } catch (error){
                this.errorCatch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.card-carrito{
    border-radius: 8px;
    height: 170px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #0000000D;
    .nombre-producto{
      height: 38px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: pre-line;
    }
    .favorite-button{
        width:24px;
        height:24px;
    }
    .button-cant{
        .minus{
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            color: var(--color-general);
            border: 1px solid var(--color-general);
        }
        .space-cant{
            width: 50px;
            color: #000000;
        }
        .plus{
            width: 24px;
            height: 24px;
            color: #FFFFFF;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: var(--color-general);
        }
    }
    .delete{
        width: 24px;
        cursor: pointer;
        height: 24px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FF1720;
        border: 1px solid #FF1720;
    }
    .pill{
        min-width: 40px;
        height: 18px;
        color: #FFFFFF;
        text-align: center;
        border-radius: 20px;
        background-color: #000000;
    }
    .pill-descuento{
        min-width: 40px;
        height: 18px;
        color: #FFFFFF;
        text-align: center;
        border-radius: 20px;
        background-color: var(--color-general);
    }
}
</style>